<template>
  <modal
    :show="show"
    :backdrop-blur="true"
    max-width="md"
    @close="$emit('close')"
  >
    <div id="airtable-connect">
      <template v-if="mode === 'database'">
        <h2 class="text-center">
          Share your
          <span
            class="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600"
          >Airtable Database</span>
        </h2>
        <p class="text-gray-500 text-center mt-5">
          Share your Airtable database and start collecting submissions.
          <span class="font-semibold">Make sure to select the select the right database(s)</span>. You might need to search for it using the search bar.
        </p>
        <div class="text-center mt-5 animate-bounce-slow">
          <LoginWithAirtable
            text="Share Airtable Database"
            @success="$emit('success')"
          />
        </div>
      </template>

      <p
        v-if="showDataSafety"
        class="text-gray-500 text-xs text-center mt-5 p-4 border bg-gray-50 rounded-md"
      >
        Your data stays in Airtable. We never store form submissions.
        <a
          class="underline text-inherit"
          href="#"
          @click.prevent="openChat"
        >Contact us</a>
        if you have any questions.
      </p>
    </div>
  </modal>
</template>

<script>
import LoginWithAirtable from '~/components/global/LoginWithAirtable.vue'

export default {
  name: 'AirtableConnectModal',
  components: { LoginWithAirtable },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    showDataSafety: {
      type: Boolean,
      default: false,
      required: false,
    },
    mode: {
      type: String,
      required: false,
      default: 'workspace',
    },
  },
  emits: ['close', 'success'],
  setup() {
    return {
      crisp: useCrisp(),
    }
  },
  data() {
    return {}
  },

  computed: {},

  watch: {},

  mounted() {},

  methods: {},
}
</script>
